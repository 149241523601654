<template>
  <div class="add-permission">
    <div style="display: flex;height: 100%;">
      <div class="add-box" style="width: 73%;">
        <div class="detail_title">
          <span class="title">获取权限信息</span>
          <div class="buttons-wrap">
            <a-button class="mr12 right" type="primary" @click="permSubmit">提交</a-button>
          </div>
        </div>
        <div class="add-form">
          <div class="input-form">
            <a-form
                ref="permFormRef"
                class="form rms-form"
                layout="vertical"
                :model="permFormState"
                :rules="permFormRules"
            >
              <a-form-item label="获取权限信息" name="permName" class="rms-form-item">
                <a-input
                    v-model:value="permFormState.permName"
                    placeholder="请输入"
                    allowClear
                    autocomplete="off"
                />
              </a-form-item>
            </a-form>
          </div>
        </div>
      </div>
      <div class="sql" style="height: 100%;width: 30%">
        <div class="sql-text">返回数据库语句如下</div>
        <div class="sql-data" style="height: 10vw;max-height: 10vw;overflow-y: auto;">
          <pre class="sdata">{{ JSON.stringify(permSql, null, 4)  }}</pre>
        </div>
      </div>
    </div>
    <div>
      <a-row>
        <a-col :span="17">
          <div class="add-box">
            <div class="detail_title">
              <span class="title">增加菜单路径</span>
              <div class="buttons-wrap">
                <a-button class="mr12 right" type="primary" @click="menuSubmit">提交</a-button>
              </div>
            </div>
            <div class="add-form">
              <div class="input-form">
                <a-form
                    ref="menuFormRef"
                    class="form rms-form"
                    layout="vertical"
                    :model="menuFormState"
                    :rules="menuFormRules"
                >
                  <a-form-item label="是否一级菜单" name="firstMenuType" class="rms-form-item">
                    <a-select
                        v-model:value="menuFormState.firstMenuType"
                        :options="formStateOps.firstMenuTypeOps"
                        placeholder="请选择"
                        allowClear
                        style="width: 100%"
                    >
                    </a-select>
                  </a-form-item>
                  <a-form-item label="菜单图标" name="icon" class="rms-form-item">
                    <a-input
                        v-model:value="menuFormState.icon"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                  <a-form-item label="操作人" name="operator" class="rms-form-item">
                    <a-input
                        v-model:value="menuFormState.operator"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                  <a-form-item label="路径path" name="path" class="rms-form-item">
                    <a-input
                        v-model:value="menuFormState.path"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                  <a-form-item label="绑定一级菜单ID" name="permFirstMenuId" class="rms-form-item">
                    <a-input
                        v-model:value="menuFormState.permFirstMenuId"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                  <a-form-item label="绑定二级菜单ID" name="permSecondMenuId" class="rms-form-item">
                    <a-input
                        v-model:value="menuFormState.permSecondMenuId"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                  <a-form-item label="权限名称" name="permName" class="rms-form-item">
                    <a-input
                        v-model:value="menuFormState.permName"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                  <a-form-item label="权限类别" name="permType" class="rms-form-item">
                    <a-select
                        v-model:value="menuFormState.permType"
                        :options="formStateOps.permTypeOps"
                        placeholder="请选择"
                        allowClear
                    >
                    </a-select>
                  </a-form-item>
                  <a-form-item label="备注" name="remark" class="rms-form-item">
                    <a-input
                        v-model:value="menuFormState.remark"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                  <a-form-item label="排序号" name="sortOrder" class="rms-form-item">
                    <a-input
                        v-model:value="menuFormState.sortOrder"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                </a-form>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="7">
          <div class="sql">
            <div class="sql-text">返回数据库语句如下</div>
            <div class="sql-data">
              <div class="sdata">{{menuSql}}</div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div>
      <a-row>
        <a-col :span="17">
          <div class="add-box">
            <div class="detail_title">
              <span class="title">增加按钮</span>
              <div class="buttons-wrap">
                <a-button class="mr12 right" type="primary" @click="btnSubmit">提交</a-button>
              </div>
            </div>
            <div class="add-form">
              <div class="input-form">
                <a-form
                    ref="btnFormRef"
                    class="form rms-form"
                    layout="vertical"
                    :model="btnFormState"
                    :rules="btnFormRules"
                >
                  <a-form-item label="操作人" name="operator" class="rms-form-item">
                    <a-input
                        v-model:value="btnFormState.operator"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                  <a-form-item label="绑定一级菜单ID" name="permFirstMenuId" class="rms-form-item">
                    <a-input
                        v-model:value="btnFormState.permFirstMenuId"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                  <a-form-item label="绑定二级菜单ID" name="permSecondMenuId" class="rms-form-item">
                    <a-input
                        v-model:value="btnFormState.permSecondMenuId"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                  <a-form-item label="路径path" name="path" class="rms-form-item">
                    <a-input
                        v-model:value="btnFormState.path"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                  <a-form-item label="权限名称" name="permName" class="rms-form-item">
                    <a-input
                        v-model:value="btnFormState.permName"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                  <a-form-item label="备注" name="remark" class="rms-form-item">
                    <a-input
                        v-model:value="btnFormState.remark"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                </a-form>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="7">
          <div class="sql" style="height: 89.5%;">
            <div class="sql-text">返回数据库语句如下</div>
            <div class="sql-data">
              <div class="sdata">{{btnSql}}</div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div>
      <a-row>
        <a-col :span="17">
          <div class="add-box">
            <div class="detail_title">
              <span class="title">增加接口</span>
              <div class="buttons-wrap">
                <a-button class="mr12 right" type="primary" @click="pathSubmit">提交</a-button>
              </div>
            </div>
            <div class="add-form">
              <div class="input-form">
                <a-form
                    ref="pathFormRef"
                    class="form rms-form"
                    layout="vertical"
                    :model="pathFormState"
                    :rules="pathFormRules"
                >
                  <a-form-item label="操作人" name="operator" class="rms-form-item">
                    <a-input
                        v-model:value="pathFormState.operator"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                  <a-form-item label="绑定按钮或菜单路径ID" name="pagepermid" class="rms-form-item">
                    <a-input
                        v-model:value="pathFormState.pagepermid"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                  <a-form-item label="绑定按钮或菜单路径" name="pagepermtype" class="rms-form-item">
                    <a-select
                        v-model:value="pathFormState.pagepermtype"
                        :options="formStateOps.pagepermtypeOps"
                        placeholder="请选择"
                        allowClear
                    >
                    </a-select>
                  </a-form-item>
                  <a-form-item label="路径path" name="path" class="rms-form-item">
                    <a-input
                        v-model:value="pathFormState.path"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                  <a-form-item label="权限名称" name="permName" class="rms-form-item">
                    <a-input
                        v-model:value="pathFormState.permName"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                  <a-form-item label="备注" name="remark" class="rms-form-item">
                    <a-input
                        v-model:value="pathFormState.remark"
                        placeholder="请输入"
                        allowClear
                        autocomplete="off"
                    />
                  </a-form-item>
                </a-form>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="7">
          <div class="sql" style="height: 89.5%;">
            <div class="sql-text">返回数据库语句如下</div>
            <div class="sql-data">
              <div class="sdata">{{pathSql}}</div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import {useGlobalPropertyHook} from "@/hooks/common";
import {onMounted, reactive, ref} from "vue";
import {dictionaryOpt} from "@/utils/common";

export default {
  name: "index",
  components: {},
  setup() {
    const { $api } = useGlobalPropertyHook();
    const menuFormRef = ref();
    const menuFormState = reactive({
      firstMenuType: '',
      icon: "#",
      operator:'',
      path:'',
      permFirstMenuId:'',
      permSecondMenuId:'',
      permName:'',
      permType:'',
      remark:'',
      sortOrder:'',
    });
    const formStateOps = {
      firstMenuTypeOps:[
        {
          value: '1',
          label: "是",
        },
        {
          value: ' ',
          label: "否",
        },
      ],
      permTypeOps:dictionaryOpt(["menu","router"]),
      pagepermtypeOps:dictionaryOpt(["btn","router"])
    }
    const menuFormRules = {
      firstMenuType: [
        {
          required: true,
          message: "Please select firstMenuType",
          trigger: "change"
        }
      ],
      icon: [
        {
          required: true,
          message: "Please input icon",
          trigger: "change"
        },
      ],
      operator: [
        {
          required: true,
          message: "Please input operator",
          trigger: "change"
        },
      ],
      path: [
        {
          required: true,
          message: "Please input path",
          trigger: "change"
        },
      ],
      permName: [
        {
          required: true,
          message: "Please input permName",
          trigger: "change"
        },
      ],
      permType: [
        {
          required: true,
          message: "Please input permType",
          trigger: "change"
        },
      ],
      remark: [
        {
          required: true,
          message: "Please input remark",
          trigger: "change"
        },
      ],
      sortOrder: [
        {
          required: true,
          message: "Please input sortOrder",
          trigger: "change"
        },
      ],
    };
    const menuSql = ref()
    const menuSubmit = () => {
      menuFormRef.value.validate().then(() => {
        $api.addMenuPerm(menuFormState).then((res) => {
          console.log(res)
          menuSql.value = res.msg
        });
      });
    }

    const btnFormRef = ref();
    const btnFormState = reactive({
      operator:'',
      permFirstMenuId:'',
      permSecondMenuId:'',
      path:'',
      permName:'',
      remark:'',
    });
    const btnFormRules = {
      operator: [
        {
          required: true,
          message: "Please input operator",
          trigger: "change"
        },
      ],
      path: [
        {
          required: true,
          message: "Please input path",
          trigger: "change"
        },
      ],
      permName: [
        {
          required: true,
          message: "Please input permName",
          trigger: "change"
        },
      ],
      remark: [
        {
          required: true,
          message: "Please input remark",
          trigger: "change"
        },
      ],
    };
    const btnSql = ref()
    const btnSubmit = () => {
      btnFormRef.value.validate().then(() => {
        $api.addBtnPerm(btnFormState).then((res) => {
          console.log(res)
          btnSql.value = res.msg
        });
      });
    }

    const pathFormRef = ref();
    const pathFormState = reactive({
      operator:'',
      pagepermid:'',
      pagepermtype:'',
      path:'',
      permName:'',
      remark:'',
    });
    const pathFormRules = {
      operator: [
        {
          required: true,
          message: "Please input operator",
          trigger: "change"
        },
      ],
      pagepermid: [
        {
          required: true,
          message: "Please input pagepermid",
          trigger: "change"
        },
      ],
      pagepermtype: [
        {
          required: true,
          message: "Please input pagepermtype",
          trigger: "change"
        },
      ],
      path: [
        {
          required: true,
          message: "Please input path",
          trigger: "change"
        },
      ],
      permName: [
        {
          required: true,
          message: "Please input permName",
          trigger: "change"
        },
      ],
      remark: [
        {
          required: true,
          message: "Please input remark",
          trigger: "change"
        },
      ],
    };
    const pathSql = ref()
    const pathSubmit = () => {
      pathFormRef.value.validate().then(() => {
        $api.addPathPerm(pathFormState).then((res) => {
          console.log(res)
          pathSql.value = res.msg
        });
      });
    }

    const permFormRef = ref();
    const permFormState = reactive({
      permName:'',
    });
    const permFormRules = {
      permName: [
        {
          required: true,
          message: "Please input name",
          trigger: "change"
        },
      ],
    };
    const permSql = ref()
    const permSubmit = () => {
      permFormRef.value.validate().then(() => {
        $api.permInfosForBackend(permFormState.permName).then((res) => {
          console.log(res)
          permSql.value = res
        });
      });
    }

    onMounted(() => {

    })
    return {
      menuFormRef,
      menuFormState,
      formStateOps,
      menuFormRules,
      menuSubmit,
      btnFormRef,
      btnFormState,
      btnFormRules,
      btnSubmit,
      pathFormRef,
      pathFormState,
      pathFormRules,
      pathSubmit,
      menuSql,
      btnSql,
      pathSql,
      permFormRef,
      permFormState,
      permFormRules,
      permSubmit,
      permSql,
    }
  }
}
</script>

<style scoped lang="less">
.add-permission {

  .add-box {
    background: #fff;
    margin: 1vw;
    padding: 1vw;
    border: 1px solid #E9E9E9;
    border-radius: 0.3vw;
    .add-form {
      padding: 1vw 0;
      display: flex;
    }
  }
  .detail_title {
    border-bottom: 1px solid #E9E9E9;
    display: flex;
    .title{
      font-size: 19px;
      font-weight: bold;
    }
  }
  .rms-form-item {
    align-items: normal !important;
    margin-bottom: 1.25vw;
  }
  .sql {
    background: #fff;
    margin: 1vw 1vw 1vw 0;
    padding: 1vw;
    border: 1px solid #E9E9E9;
    border-radius: 0.3vw;
    height: 92%;
    .sql-text {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0.2vw;
    }
    .sql-data {
      border: 1px solid #E9E9E9;
      border-radius: 0.3vw;
      height: 92%;
      .sdata {
        padding: 0.5vw;
        word-wrap: break-word;
      }
    }
  }
}
</style>
